<template>
  <div class="actions-cell">
    <template v-for="action in params.actions">
      <i v-if="action.show?.(params.data)" class="fa" :class="action.icon" :title="action.title" @click="action.onClick(params.data.id)"></i
    ></template>
  </div>
</template>

<script>
export default {
  name: 'ActionsCellRenderer',
};
</script>

<style>
.actions-cell i {
  margin: 0 0.4em;
  cursor: pointer;
}
</style>
